<template>
  <div class="column pt-0">
    <div class="has-background-white p-4">
      <h5 class="mb-4 has-text-weight-medium">ЗАРЕГИСТРИРОВАТЬ ПОЛЬЗОВАТЕЛЯ</h5>
      <form @submit.prevent="createUser">
        <p class="mb-3">Введите данные</p>

        <div class="control mr-4 input-set mb-2">
          <span>Имя</span>
          <input
            class="input"
            v-model.trim="name"
            type="text"
            placeholder="Имя"
            required
          >
        </div>

        <div class="control  mr-4 input-set mb-2">
          <span>Фамилия</span>
          <input
            class="input"
            v-model.trim="surname"
            type="text"
            placeholder="Фамилия"
            required
          >
        </div>

        <div class="control  mr-4 input-set mb-2">
          <span>Логин</span>
          <input
            class="input"
            v-model.trim="login"
            type="text"
            placeholder="Логин"
            required
          >
        </div>

        <div class="control  mr-4 input-set mb-2">
          <span>Email</span>
          <input
            class="input"
            v-model.trim="email"
            type="email"
            placeholder="Email"
            required
          >
        </div>

        <div class="control  mr-4 input-set mb-2">
          <span>Номер телефона</span>
          <input
            class="input"
            type="number"
            v-model.trim="phone"
            placeholder="Номер телефона"
            required
          >
        </div>

        <div class="control  mr-4 input-set mb-2">
          <span>Должность</span>
          <input
            class="input"
            type="text"
            v-model.trim="position"
            placeholder="Должность"
            required
          >
        </div>

        <div class="control  mr-4 input-set mb-2">
          <span>Пароль</span>
          <input
            class="input"
            type="password"
            v-model.trim="password"
            placeholder="Пароль"
            required
          >
        </div>

        <b-field label="Роль пользователя">
          <b-select
            placeholder="Select a name"
            v-model.trim="role_id"
            required
          >
            <option
              v-for="option in data_to_role"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="role_id === 3" label="Компания">
          <b-taginput
            v-model="company_ids"
            :data="filteredCompanies"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            field="name"
            placeholder="Выберите компании"
            :disabled="role_id !== 3"
            @typing="getCompaniesFilteredTags"
            @input="handleValueCompanies"
          />
        </b-field>

        <b-field v-else label="Компания">
          <b-select
            placeholder="Выберите компанию"
            v-model="company_id"
            required
          >
            <option
              v-for="option in company"
              :value="option.id"
              :key="option.id"
            >
              {{ option.name }}
            </option>
          </b-select>
        </b-field>

        <b-field label="Филиал">
          <b-taginput
            v-model="branch_ids"
            :data="filteredBranches"
            autocomplete
            :allow-new="false"
            :open-on-focus="true"
            field="name"
            placeholder="Выберите филиал"
            :disabled="!company_id || role_id === 3"
            @typing="getBranchesFilteredTags"
            @input="handleValueBranches"
          >
          </b-taginput>
        </b-field>

        <button class="button is-info mt-5">Зарегистрировать</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      login: null,
      password: null,
      role_id: null,
      name: null,
      surname: null,
      branch_ids: [],
      phone: null,
      email: null,
      position: null,
      company: null,
      filteredBranches: [],
      filteredCompanies: [],
      company_id: null,
      company_ids: [],
      selectedBranches: [],
      selectedCompanies: [],
      branch: null,
      data_to_role: null,
    }
  },
  watch: {
    company_id(newValue) {
      this.selectedBranches = []
      this.branch_ids = []
      if (newValue) this.GetBranch(this.company_id)
    },
    role_id() {
      this.company_ids = []
      this.branch_ids = []
      this.selectedBranches = []
      this.selectedCompanies = []
      this.company_id = undefined
    },
  },
  created() {
    this.GetCompany()
    this.GetRole()
  },
  methods: {
    handleValueBranches(value) {
      this.selectedBranches = value.map(branch => branch.id)
    },
    handleValueCompanies(value) {
      this.selectedCompanies = value.map(company => company.id)
    },
    getBranchesFilteredTags(text) {
      this.filteredBranches = this.branch.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    getCompaniesFilteredTags(text) {
      this.filteredCompanies = this.company.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    async GetCompany() {
      await this.$axios.get(`admin/companies`).then(response => {
        this.company = response.data.data
        this.filteredCompanies = response.data.data || []
      })
    },
    async GetRole() {
      await this.$axios.get(`users/create`).then(response => {
        this.data_to_role = response.data.data
      })
    },
    async GetBranch(e) {
      await this.$axios.get(`admin/companies/${e}/branches/`).then(response => {
        this.branch = response.data.data
        this.filteredBranches = response.data.data || []
      })
    },
    async createUser() {
      let data = {
        "login": this.login,
        "password": this.password,
        "role_id": this.role_id,
        "first_name": this.name,
        "phone": this.phone,
        "email": this.email,
        "last_name": this.surname,
        "position": this.position,
        ...(this.role_id !== 3 && { "branch_ids": this.selectedBranches }),
        ...(this.role_id === 3 ? { companies_ids: this.selectedCompanies } : { company_id: this.company_id }),
      }

      await this.$axios.post(`users`, data).then(response => {
        if (response.data.code === 201) {
          this.$router.push("/users-list")
        }
      })
    },

  },
}
</script>


